
.search-bar li.selected ::after {
    content: '';
    position:absolute;
    width:100%; height:100%;
    top:0; left:0;
    background:rgba(0,0,0,0.1);
    opacity:0.08;
}

.search-bar li:hover ::after {
    content: '';
    position:absolute;
    width:100%; height:100%;
    top:0; left:0;
    background:rgba(0,0,0,0.1);
    opacity:0.08;
}